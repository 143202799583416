import {useDispatch, useSelector} from 'react-redux';
import useJoinedTeam from '../../hooks/useJoinedTeam';
import { RootState } from '../../redux/rootReducer';
import { hideHistory } from '../../redux/modules/interaction-history';
import { Interaction, setInteraction } from '../../redux/modules/interaction';
import { showInteraction } from '../../redux/actions';
import styled from '@emotion/styled';
import {black} from '../../theme/vars';
import { DJ } from '../../constants/Pusher.constants';
import DjHistoryItem from './DjHistoryItem';
import DjHeader from './DjHeader';
import ButtonIcon from '../buttons/ButtonIcon';
import whiteX from '../../assets/icons/white-x.svg';
import { DJ_TITLE } from './dj-content';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .dj-history-title {
    font-family: Roboto-Bold;
    color: white;
    padding: 1rem;
  }

  .dj-history-list {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .dj-history-entry {
    color: white;
    margin: 1rem;
    padding: 1rem;
    border: 1px #ffffff solid;
    border-radius: 10px;
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

const DjHistoryList = () => {
  const dispatch = useDispatch();
  const joinedTeam = useJoinedTeam();
  const history = useSelector((state: RootState) => state.interactionHistory);
  const entries = history.history
    .filter(entry => entry.interaction?.interactionType === DJ && !entry.interaction.realtime && entry.timestamp)
    .sort((a, b) => b.timestamp - a.timestamp);

  const {color: backgroundColor} = joinedTeam;

  const onHideHistory = () => {
    dispatch(hideHistory());
  };

  const onTriggerInteraction = (interaction: Interaction) => {
    console.log('onTriggerInteraction');
    console.dir(interaction);
    dispatch(setInteraction(interaction));
    dispatch(showInteraction());
  };

  return (
    <Container>
      <DjHeader
        header={DJ_TITLE}
        backgroundColor={backgroundColor}
        joinedTeam={joinedTeam}
        leftContainer={(
          <div className="closeButton">
            <ButtonIcon
              icon={whiteX}
              alt="Close"
              onClick={() => onHideHistory()}
            />
          </div>
        )}
      />
      <div className="dj-history-list">
        <div className='dj-history-title'>Recent Activity</div>
        {entries.map(entry => (
          <div key={entry.interaction.interactionId} className="dj-history-entry">
            <DjHistoryItem 
              historyEntry={entry} 
              disabled={entry.completed} 
              hasDisabledStyle={entry.completed}
              backgroundColor={backgroundColor}
              onPressButton={() => onTriggerInteraction(entry.interaction)}
            />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default DjHistoryList;