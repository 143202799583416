import {useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {buttonGreen, black} from '../../theme/vars';

// components
import DjSongButton from './DjSongButton';
import DjHeader from './DjHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';

import {selectResultById} from '../../redux/modules/dj';
import {RootState} from '../../redux/rootReducer';
import Title from './Title';
import { DJ_CTA_MESSAGE } from './dj-content';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type Props = {
  interaction: Record<string, any>;
  backgroundColor: string;
  clearInteractionType: any;
  onDjCompletion: (id: any) => void;
  isWatchPartyConnected?: boolean;
};

const DjVoteResults = ({
  interaction,
  clearInteractionType,
  onDjCompletion,
  backgroundColor,
  isWatchPartyConnected,
}: Props) => {
  const {metadata: interactionMetaData, interactionId} = interaction;
  const joinedTeam = useJoinedTeam();

  const result = useSelector((state: RootState) =>
    selectResultById(state, interactionId),
  );

  const nextStep = useCallback(() => {
    clearInteractionType();
  }, [clearInteractionType]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextStep();
      onDjCompletion(interactionId);
    }, 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [nextStep, onDjCompletion, interactionId]);

  const getPercent = (id: any) => {
    const matchingPollResultOption = result?.options.find((r: any) => {
      return r.id === id;
    });

    const percent = matchingPollResultOption?.percentage || 0;
    return Number(percent);
  };

  const getBackgroundColor = (id: any) => {
    if (result) {
      return buttonGreen;
    }
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <DjHeader
            header={'RESULTS'}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Title text={DJ_CTA_MESSAGE} />
            <div className="buttonContainer">
              {interactionMetaData.songs.map((song: any) => {
                return (
                  <div key={song.id} className="buttonWrapper">
                    <DjSongButton
                      song={song}
                      disabled={true}
                      percent={getPercent(song.id)}
                      shouldAnimateWidth={true}
                      backgroundColor={getBackgroundColor(song.id) || ''}
                      animateTextColor={false}
                      shouldAnimateBackgroundColor={false}
                      showPercent={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default DjVoteResults;
