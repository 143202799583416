import styled from '@emotion/styled';
import {defaultThemeColor} from '../../theme/vars';
import React from 'react';
import { DJ } from '../../constants/Pusher.constants';

type ContainerProps = {
  background: string;
  type?: string;
  isWatchPartyConnected?: boolean;
};

const Container = styled('div')<ContainerProps>`
  display: flex;
  position: absolute;
  z-index: 102;
  justify-content: center;
  align-items: center;
  height: ${props => props.type === DJ ? '100vh' : '100%'};
  width: 100vw;
  background-color: ${props =>
    props.background ? `#${props.background}` : defaultThemeColor};
  .trivia-content {
    height: 100%;
    justify-content: center;
  }
  .dj-content {
    height: 100vh;
    overflow-y: scroll;
  }
  .interaction-content {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 0 0.75rem 0.75rem;
    background-color: ${props =>
      props.background ? `#${props.background}` : defaultThemeColor};
  }
`;

type Props = {
  backgroundColor: string;
  content: React.ReactNode;
  type?: string;
  isWatchPartyConnected: boolean;
};

const InteractionContainer = ({
  backgroundColor,
  content,
  type,
  isWatchPartyConnected,
}: Props) => {
  let contentClasses;
  if (type === DJ) {
    contentClasses = 'interaction-content dj-content';
  } else {
    contentClasses = 'interaction-content trivia-content';
  }
  return (
    
    <Container
      id='interaction-container'
      background={backgroundColor}
      type={type}
      isWatchPartyConnected={isWatchPartyConnected}
      >
        <div className={contentClasses}>{content}</div>
    </Container>
  );
};

export default InteractionContainer;
