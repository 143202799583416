import {useCallback, useEffect} from 'react';
import styled from '@emotion/styled';

// styles
import {transparent} from '../../theme/vars';

// components
import TriviaButton from './TriviaButton';
import Question from './Question';
import TriviaPollHeader from './TriviaPollHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';

import {getAnswer} from '../../utils/trivia';

// constants
import TriviaCountdown from './TriviaCountdown';
import {triviaStep} from '../../constants/Trivia.constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: black;
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type TriviaTransitionToAnswerProps = {
  interaction: any;
  setStep: (step: triviaStep) => void;
  activeAnswer: string | null;
  backgroundColor: string;
  isWatchPartyConnected: boolean;
};

const TriviaTransitionToAnswer = ({
  interaction,
  setStep,
  activeAnswer,
  backgroundColor,
  isWatchPartyConnected,
}: TriviaTransitionToAnswerProps) => {
  const {metadata: interactionMetaData} = interaction;
  const joinedTeam = useJoinedTeam();

  const nextStep = useCallback(() => {
    setStep(triviaStep.SHOW_ANSWER_TO_QUESTION);
  }, [setStep]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextStep();
    }, 1000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [nextStep]);

  const getCountDown = () => {
    return <TriviaCountdown isDone={true} />;
  };

  const getDelay = (id: any) => {
    let buttonAnimationDelay;
    const answer = getAnswer(interactionMetaData.options, interaction);
    const otherAnswers: {index: any; id: any}[] = [];

    interactionMetaData.options.forEach((option: {id: string}, index: any) => {
      if (option.id !== answer && option.id !== activeAnswer) {
        otherAnswers.push({index, id: option.id});
      }
    });
    otherAnswers.forEach((a, index) => {
      if (index === 0 && a.id === id) {
        buttonAnimationDelay = 0;
      }
      if (index === 1 && a.id === id) {
        buttonAnimationDelay = 1.5;
      }
      if (index === 2 && a.id === id) {
        buttonAnimationDelay = 3;
      }
    });
    if (id === activeAnswer) {
      buttonAnimationDelay = 3;
    }
    if (id === answer) {
      buttonAnimationDelay = 4.5;
    }
    return buttonAnimationDelay;
  };

  const isWrongAnswer = (id: any) => {
    return (
      id === activeAnswer &&
      activeAnswer !== getAnswer(interactionMetaData.options, interaction)
    );
  };

  const shouldAnimateBackgroundColor = (id: any) => {
    const isAnswer = getAnswer(interactionMetaData.options, interaction) === id;

    if (!isAnswer && !isWrongAnswer(id)) {
      return false;
    }
    return true;
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <TriviaPollHeader
            header="FAN TRIVIA"
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            type="trivia"
            headerFeature={getCountDown()}
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Question text={interactionMetaData.question} />
            <div className="buttonContainer">
              {interactionMetaData.options.map((option: any) => {
                return (
                  <div key={option.id} className="buttonWrapper">
                    <TriviaButton
                      option={option}
                      activeAnswer={activeAnswer}
                      delay={getDelay(option.id)}
                      disabled={true}
                      percent={100}
                      shouldAnimateWidth={false}
                      backgroundColor={transparent}
                      animateTextColor={
                        !shouldAnimateBackgroundColor(option.id)
                      }
                      shouldAnimateBackgroundColor={shouldAnimateBackgroundColor(
                        option.id,
                      )}
                      isAnswer={
                        getAnswer(interactionMetaData.options, interaction) ===
                        option.id
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default TriviaTransitionToAnswer;
