import {useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';

// styles
import {
  black,
  buttonGreen,
  buttonRed,
  transparent,
  white,
} from '../../theme/vars';

// components
import TriviaButton from './TriviaButton';
import TriviaPollHeader from './TriviaPollHeader';
import Question from './Question';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';

import {getAnswer} from '../../utils/trivia';
import styled from '@emotion/styled';
import {TailSpin} from 'react-loader-spinner';
import {triviaStep} from '../../constants/Trivia.constants';
import {selectResultById as selectTriviaResultById} from '../../redux/modules/trivia';
import {selectResultById as selectPollResultById} from '../../redux/modules/poll';
import {RootState} from '../../redux/rootReducer';

const TIMEOUT = 15000;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

const CenteredLoader = styled(TailSpin)`
  display: flex;
`;

type Props = {
  interaction: Record<string, any>;
  setStep: (num: number) => void;
  activeAnswer: string | null;
  backgroundColor: string;
  poll?: any;
  clearInteractionType: any;
  isWatchPartyConnected?: boolean;
};

const TriviaLoadingResults = ({
  interaction,
  activeAnswer,
  setStep,
  poll,
  clearInteractionType,
  backgroundColor,
  isWatchPartyConnected,
}: Props) => {
  const {metadata: interactionMetaData} = interaction;
  const joinedTeam = useJoinedTeam();

  const nextStep = useCallback(() => {
    setStep(triviaStep.RESULTS);
  }, [setStep]);

  const selectResultById = poll ? selectPollResultById : selectTriviaResultById;
  const result = useSelector((state: RootState) =>
    selectResultById(state, interaction.interactionId),
  );

  const loadingTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    loadingTimeout.current = setTimeout(() => {
      clearInteractionType();
    }, TIMEOUT);

    return () => {
      loadingTimeout.current && clearTimeout(loadingTimeout.current);
    };
  }, [clearInteractionType]);

  useEffect(() => {
    if (result && loadingTimeout.current) {
      clearTimeout(loadingTimeout.current);
      nextStep();
    }
  }, [nextStep, result]);

  const getBackground = (id: string) => {
    if (poll) {
      return transparent;
    }
    const isAnswer = getAnswer(interactionMetaData.options, interaction) === id;
    const wrongAnswer =
      id === activeAnswer &&
      activeAnswer !== getAnswer(interactionMetaData.options, interaction);
    let color;
    if (!isAnswer || !wrongAnswer) {
      color = transparent;
    }
    if (isAnswer) {
      color = buttonGreen;
    }
    if (wrongAnswer) {
      color = buttonRed;
    }

    return color;
  };

  const hasDisabledStyle = (id: string) => {
    return (
      id !== activeAnswer &&
      id !== getAnswer(interactionMetaData.options, interaction)
    );
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <TriviaPollHeader
            header={'TABULATING...'}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            type={poll ? 'poll' : 'trivia'}
            headerFeature={
              <CenteredLoader
                color={white}
                height={isWatchPartyConnected ? 40 : 65}
                width={isWatchPartyConnected ? 40 : 65}
              />
            }
            isWatchPartyConnected={isWatchPartyConnected}
          />

          <div className="questionWrapper">
            <Question text={interactionMetaData.question} />
            <div className="buttonContainer">
              {interactionMetaData.options.map(
                (option: {id: string}, index: any) => {
                  return (
                    <div key={option.id} className="buttonWrapper">
                      <TriviaButton
                        option={option}
                        disabled={true}
                        hasDisabledStyle={!poll && hasDisabledStyle(option.id)}
                        percent={100}
                        shouldAnimateWidth={false}
                        backgroundColor={getBackground(option.id) || ''} // TODO: fix this
                      />
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default TriviaLoadingResults;
