import {useCallback, useEffect, useState} from 'react';
import styled from '@emotion/styled';

// styles
import {black, curiousBlue, transparent} from '../../theme/vars';

// components
import DjSongButton from './DjSongButton';
import DjRequestCountdown from './DjRequestCountdown';
import DjHeader from './DjHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';
import useJoinedGame from '../../hooks/useJoinedGame';

import {trackEventData} from '../../utils/analytics';
import {
  DJ_SUBMITTED_CATEGORY,
  DJ_ACTION,
} from '../../constants/analytics.constants';
import {djStep} from '../../constants/Dj.constants';
import Title from './Title';
import { DJ_CTA_MESSAGE, DJ_TITLE } from './dj-content';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type Props = {
  interaction: any;
  handleSubmit: (body: any) => void;
  userId: string;
  setStep: (num: number, songVotes: any) => void;
  backgroundColor: string;
  isWatchPartyConnected?: boolean;
  isGuestUser: boolean;
  timeStarted?: number;
};

const DjCountdownVote = ({
  interaction,
  handleSubmit,
  userId,
  setStep,
  backgroundColor,
  isWatchPartyConnected = false,
  isGuestUser,
  timeStarted,
}: Props) => {
  const {metadata: interactionMetaData} = interaction;

  const [songVotes, setSongVotes] = useState<Set<string>>(new Set());

  const joinedTeam = useJoinedTeam();
  const joinedGame = useJoinedGame();

  const addVote = (songId: string) => {
    if (songVotes.size < 5) {
      setSongVotes(new Set(songVotes).add(songId));
    }
  };

  const removeVote = (songId: string) => {
    const newVotes = new Set(songVotes);
    newVotes.delete(songId);
    setSongVotes(newVotes);
  };

  const submitVote = () => {
    console.log(`submitVote realtime=${interaction.realtime}`);
    console.dir(songVotes);
    const body = {
      interactionId: interaction ? interaction.interactionId : null,
      realtime: interaction.realtime,
      djRequestId: interactionMetaData ? interactionMetaData.djRequestId : null,
      songs: Array.from(songVotes),
      userId: userId,
      isGuestUser,
    };

    songVotes.forEach(songId => {
      trackEventData(DJ_SUBMITTED_CATEGORY, {
        action: DJ_ACTION,
        interaction_id: body.interactionId,
        dj_request_id: body.djRequestId,
        song_id: songId,
        user_id: userId,
        game_id: joinedGame?.id,
        team_id: joinedTeam?.id,
      });
    });
    handleSubmit(body);
  };

  const getCountDown = () => {
    return (
      <DjRequestCountdown
        timeStarted={timeStarted}
        countDownComplete={() =>
          setStep(djStep.LOADING_VOTE_RESULTS, songVotes)
        }
      />
    );
  };

  useEffect(() => {
    console.log('checking songVotes');
    console.dir(songVotes);
    if (songVotes.size > 0 && songVotes.size <= interactionMetaData.songs.length) {
      submitVote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songVotes]);

  return (
    <Container>
      {interactionMetaData && (
        <>
          <DjHeader
            header={DJ_TITLE}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            headerFeature={getCountDown()}
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Title text={DJ_CTA_MESSAGE} />
            <div className="buttonContainer">
              {interactionMetaData.songs.map((song: any) => {
                return (
                  <div key={song.id} className="buttonWrapper">
                    <DjSongButton
                      disabled={false}
                      song={song}
                      onPressSongButton={id => {
                        if (songVotes.has(id)) {
                          removeVote(id);
                        } else {
                          addVote(id)
                        }
                      }}
                      percent={100}
                      shouldAnimateWidth={false}
                      backgroundColor={
                        songVotes.has(song.id) ? curiousBlue : transparent
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default DjCountdownVote;
