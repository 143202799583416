import {useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';

// styles
import {
  black,
  transparent,
  white,
} from '../../theme/vars';

// components
import DjSongButton from './DjSongButton';
import DjHeader from './DjHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';

import styled from '@emotion/styled';
import {TailSpin} from 'react-loader-spinner';
import {djStep} from '../../constants/Dj.constants';
import {selectResultById} from '../../redux/modules/dj';
import {RootState} from '../../redux/rootReducer';
import Title from './Title';
import { DJ_CTA_MESSAGE } from './dj-content';

const TIMEOUT = 15000;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

const CenteredLoader = styled(TailSpin)`
  display: flex;
`;

type Props = {
  interaction: Record<string, any>;
  setStep: (num: number) => void;
  songVote: string | null;
  backgroundColor: string;
  clearInteractionType: any;
  isWatchPartyConnected?: boolean;
};

const DjLoadingResults = ({
  interaction,
  songVote,
  setStep,
  clearInteractionType,
  backgroundColor,
  isWatchPartyConnected,
}: Props) => {
  const {metadata: interactionMetaData} = interaction;
  const joinedTeam = useJoinedTeam();

  const nextStep = useCallback(() => {
    setStep(djStep.RESULTS);
  }, [setStep]);

  const result = useSelector((state: RootState) =>
    selectResultById(state, interaction.interactionId),
  );

  const loadingTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    loadingTimeout.current = setTimeout(() => {
      clearInteractionType();
    }, TIMEOUT);

    return () => {
      loadingTimeout.current && clearTimeout(loadingTimeout.current);
    };
  }, [clearInteractionType]);

  useEffect(() => {
    if (result && loadingTimeout.current) {
      clearTimeout(loadingTimeout.current);
      nextStep();
    }
  }, [nextStep, result]);

  const getBackground = (id: string) => {
    return transparent;
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <DjHeader
            header={'TABULATING...'}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            headerFeature={
              <CenteredLoader
                color={white}
                height={isWatchPartyConnected ? 40 : 65}
                width={isWatchPartyConnected ? 40 : 65}
              />
            }
            isWatchPartyConnected={isWatchPartyConnected}
          />

          <div className="questionWrapper">
            <Title text={DJ_CTA_MESSAGE} />
            <div className="buttonContainer">
              {interactionMetaData.songs.map(
                (song: any, index: any) => {
                  return (
                    <div key={song.id} className="buttonWrapper">
                      <DjSongButton
                        song={song}
                        disabled={true}
                        percent={100}
                        shouldAnimateWidth={false}
                        backgroundColor={getBackground(song.id) || ''} // TODO: fix this
                      />
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default DjLoadingResults;
