//asset
import styled from '@emotion/styled';
import {black} from '../../theme/vars';
import HTImage from '../media/HTImage';

// type
import {AssetType} from '../../types/assetType';
import {HeaderLarge} from '../typography/HeaderLarge';
import {clampBuilder} from '../../utils/style';
import ThreeSectionTitleHeader from '../headers/ThreeSectionTitleHeader';
import {getTeamImage} from '../../utils/media';

const ClampedHeaderLarge = styled(HeaderLarge)`
  font-size: ${clampBuilder(1, 2)};
  line-height: ${clampBuilder(1, 2)};
  align-self: center;
  padding-bottom: 1rem;
  width: 30vw;
  white-space: nowrap;
`;

type ContainerProps = {
  background: string;
  isWatchPartyConnected?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: 0.75rem;

  .closeButton {
    align-self: center;
    margin: 0;
  }

  .wordMarkWrapper {
    align-self: center;
    max-width: 15rem;
    margin: 0 2rem;
  }

  .sponsorWrapper {
    max-width: 20vw;
    clip-path: polygon(5vw 0, 150% 0, 150% 150%, 0 150%);
    background-color: ${black};
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem;
  }

  .triviaPollBar {
    background-color: ${black};
    height: 0.75rem;
    margin: -1px 0;
  }

  .headerFeature {
    position: absolute;
    bottom: -2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerFeatureContainer {
    display: flex;
    align-items: center;
    background-color: ${black};
    height: ${({isWatchPartyConnected}) =>
      isWatchPartyConnected ? `50px` : `75px`};
    justify-content: center;
    width: ${({isWatchPartyConnected}) =>
      isWatchPartyConnected ? `50px` : `75px`};
    border-radius: 50px;
    border: 5px ${black} solid;
  }
`;

const SponsorImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

type Props = {
  header: string;
  backgroundColor: string;
  joinedTeam: any;
  headerFeature?: any;
  leftContainer?: any;
  isWatchPartyConnected?: boolean;
};

const DjHeader = ({
  header,
  backgroundColor,
  joinedTeam,
  headerFeature,
  leftContainer,
  isWatchPartyConnected,
}: Props) => {
  const sponsoredImage = getTeamImage({
    type: AssetType.DjSponsorLogo,
    teamId: joinedTeam.id,
  });

  return (
    <Container
      background={backgroundColor}
      isWatchPartyConnected={isWatchPartyConnected}>
      <>
        <ThreeSectionTitleHeader
          TitleComponent={ClampedHeaderLarge}
          backgroundColor="unset"
          padding="0"
          maxWidth="unset"
          height={isWatchPartyConnected ? '4rem' : '5rem'}
          alignItems="stretch"
          leftContainer={leftContainer}
          rightContainer={
            sponsoredImage && (
              <div className="sponsorWrapper">
                <SponsorImage
                  alt={sponsoredImage.title || 'sponsored'}
                  src={sponsoredImage.mediaUrl}
                />
              </div>
            )
          }>
          {header}
        </ThreeSectionTitleHeader>

        <div className="triviaPollBar" />

        {headerFeature && (
          <div className="headerFeature">
            <div className="headerFeatureContainer">{headerFeature}</div>
          </div>
        )}
      </>
    </Container>
  );
};

export default DjHeader;
