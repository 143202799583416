// styles
import {buttonGreen, buttonRed, grey, white} from '../../theme/vars';
import TouchableOpacity from '../../containers/touchableOpacity/TouchableOpacity';
import styled from '@emotion/styled';
import {motion} from 'framer-motion';

type ContainerProps = {
  percent?: number;
};

const Container = styled(TouchableOpacity)<ContainerProps>`
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0;

  .buttonOutline {
    border: 2px solid white;
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }

  .disabledButtonOutline {
    border-color: ${grey};
  }
`;

const ButtonFill = styled(motion.div)<ContainerProps>`
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${props => props.percent}%;
  border-bottom-right-radius: ${props => (props.percent === 100 ? '8px' : '0')};
  border-top-right-radius: ${props => (props.percent === 100 ? '8px' : '0')};
`;

const TextContainer = styled(motion.div)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 5px;
  padding: 5px;
  position: absolute;

  &.disabledText {
    color: ${grey};
  }
`;

const SongText = styled(motion.div)<ContainerProps>`
  color: ${white};
  font-family: 'Roboto-Bold';
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;

  &.disabledText {
    color: ${grey};
  }
`;

const ArtistText = styled(motion.div)<ContainerProps>`
  color: ${white};
  font-family: 'Roboto-Bold';
  font-size: 10px;
  text-
  text-transform: uppercase;

  &.disabledText {
    color: ${grey};
  }
`;

const PercentText = styled(motion.div)`
  color: ${white};
  font-family: 'Roboto-Regular';
  font-size: 14px;
  right: 20px;
  line-height: 15px;
  position: absolute;
`;

const pageTransition = (delay: number | undefined) => {
  return {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.7,
    delay,
  };
};

const buttonVariants = {
  initial: ({
    backgroundColor,
    percent,
    shouldAnimateWidth,
    shouldAnimateBackgroundColor,
    isAnswer,
    isNotActive,
  }: {
    backgroundColor: string | undefined;
    percent: number;
    shouldAnimateWidth: boolean;
    shouldAnimateBackgroundColor: boolean;
    isAnswer: boolean;
    isNotActive: boolean;
  }) => {
    return {
      width: `${shouldAnimateWidth ? 0 : percent}%`,
      backgroundColor: shouldAnimateBackgroundColor
        ? isAnswer
          ? isNotActive
            ? 'rgba(0, 0, 0, 0)'
            : 'rgb(50, 122, 194)'
          : 'rgb(50, 122, 194)'
        : backgroundColor,
    };
  },
  in: ({
    backgroundColor,
    percent,
    shouldAnimateWidth,
    shouldAnimateBackgroundColor,
    isAnswer,
  }: {
    backgroundColor: string | undefined;
    percent: number;
    shouldAnimateWidth: boolean;
    shouldAnimateBackgroundColor: boolean;
    isAnswer: boolean;
  }) => {
    return {
      ...(shouldAnimateWidth && {width: `${percent}%`}),
      backgroundColor: shouldAnimateBackgroundColor
        ? isAnswer
          ? buttonGreen
          : buttonRed
        : backgroundColor,
    };
  },
};

const textVariants = {
  initial: (animateTextColor: any) => ({
    ...(animateTextColor && {borderColor: 'rgb(255, 255, 255)'}),
  }),
  in: (animateTextColor: any) => ({
    ...(animateTextColor && {borderColor: 'rgb(119, 119, 119)'}),
  }),
};

type Props = {
  disabled: boolean;
  hasDisabledStyle?: boolean;
  backgroundColor: string;
  delay?: number;
  animateTextColor?: boolean;
  shouldAnimateBackgroundColor?: boolean;
  song: any;
  onPressSongButton?: (id: string) => void;
  percent: number;
  shouldAnimateWidth: boolean;
  showPercent?: boolean;
};

const DjSongButton = ({
  disabled,
  hasDisabledStyle = false,
  backgroundColor,
  delay,
  animateTextColor,
  shouldAnimateBackgroundColor,
  song,
  onPressSongButton,
  percent,
  shouldAnimateWidth,
  showPercent = false,
}: Props) => {
  const transition = pageTransition(delay);

  return (
    <Container
      disabled={disabled}
      handleClick={() => {
        onPressSongButton?.(song.id);
      }}>
      <motion.div
        className={`buttonOutline${
          (hasDisabledStyle && ' disabledButtonOutline') || ''
        }`}
        custom={animateTextColor}
        initial="initial"
        animate="in"
        transition={transition}
        variants={textVariants}
      />
      <ButtonFill
        percent={percent}
        custom={{
          backgroundColor,
          percent,
          shouldAnimateWidth,
          shouldAnimateBackgroundColor,
        }}
        variants={buttonVariants}
        initial="initial"
        animate="in"
        transition={transition}
      />
      <TextContainer>
        <SongText
          className={`${(hasDisabledStyle && 'disabledText') || ''}`}
          custom={animateTextColor}
          initial="initial"
          animate="in"
          transition={transition}
          variants={textVariants}>
          {song.song_name}
        </SongText>
        <ArtistText
          className={`${(hasDisabledStyle && 'disabledText') || ''}`}
          custom={animateTextColor}
          initial="initial"
          animate="in"
          transition={transition}
          variants={textVariants}>
          {song.artist_name}
        </ArtistText>
      </TextContainer>
      {showPercent && percent >= 0 && (
        <PercentText
          custom={animateTextColor}
          initial="initial"
          animate="in"
          transition={transition}
          variants={textVariants}>
          {`${percent}%`}
        </PercentText>
      )}
    </Container>
  );
};

export default DjSongButton;
