export enum AssetType {
  TeamLogo = 'team_logo',
  OutlineLogo = 'outline_logo',
  Wordmark = 'wordmark',
  GameCard = 'gamecard',
  TriviaSponsorLogo = 'trivia_sponsor_logo',
  PollSponsorLogo = 'poll_sponsor_logo',
  DjSponsorLogo = 'dj_sponsor_logo',
  TeamScreamSponsorLogo = 'teamscream_sponsor_logo',
  StatsSponsorLogo = 'stats_sponsor_logo',
  FeedNotAvailableImage = 'feed_not_available_image',
  ChatUnavailableImage = 'chat_unavailable_image',
}
