import {useState} from 'react';
import styled from '@emotion/styled';

// styles
import {black, curiousBlue, transparent} from '../../theme/vars';

// components
import TriviaButton from './TriviaButton';
import TriviaCountdown from './TriviaCountdown';
import TriviaPollHeader from './TriviaPollHeader';
import Question from './Question';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';
import useJoinedGame from '../../hooks/useJoinedGame';

import {trackEventData} from '../../utils/analytics';
import {
  TRIVIA_SUBMITTED_CATEGORY,
  TRIVIA_ACTION,
  POLL_SUBMITTED_CATEGORY,
  POLL_ACTION,
} from '../../constants/analytics.constants';
import {triviaStep} from '../../constants/Trivia.constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type Props = {
  interaction: any;
  handleSubmit: (body: any) => void;
  userId: string;
  setStep: (num: number, str: string) => void;
  poll?: boolean;
  backgroundColor: string;
  isWatchPartyConnected?: boolean;
  isGuestUser: boolean;
  timeStarted?: number;
};

const TriviaCountdownQuestion = ({
  interaction,
  handleSubmit,
  userId,
  setStep,
  poll = false,
  backgroundColor,
  isWatchPartyConnected = false,
  isGuestUser,
  timeStarted,
}: Props) => {
  const {metadata: interactionMetaData} = interaction;
  const [activeAnswer, setActiveAnswer] = useState<any>(null);
  const joinedTeam = useJoinedTeam();
  const joinedGame = useJoinedGame();

  const submitAnswer = (answer: number) => {
    let body;
    if (poll && interactionMetaData && interactionMetaData.pollId) {
      body = {
        interactionId: interaction ? interaction.interactionId : null,
        pollId: interactionMetaData ? interactionMetaData.pollId : null,
        optionId: answer,
        userId: userId,
        isGuestUser,
      };
    } else {
      body = {
        interactionId: interaction ? interaction.interactionId : null,
        triviaId: interactionMetaData ? interactionMetaData.triviaId : null,
        optionId: answer,
        userId: userId,
        isGuestUser,
      };
    }
    trackEventData(poll ? POLL_SUBMITTED_CATEGORY : TRIVIA_SUBMITTED_CATEGORY, {
      action: poll ? POLL_ACTION : TRIVIA_ACTION,
      interaction_id: body.interactionId,
      trivia_poll_id: poll ? body.pollId : body.triviaId,
      user_id: userId,
      game_id: joinedGame?.id,
      team_id: joinedTeam?.id,
    });
    handleSubmit(body);
  };

  const getCountDown = () => {
    return (
      <TriviaCountdown
        timeStarted={timeStarted}
        countDownComplete={() =>
          setStep(
            poll
              ? triviaStep.LOADING_POLL_RESULTS
              : triviaStep.TRANSITION_TO_ANSWER,
            activeAnswer,
          )
        }
      />
    );
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <TriviaPollHeader
            header={poll ? 'FAN POLL' : 'FAN TRIVIA'}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            type={poll ? 'poll' : 'trivia'}
            headerFeature={getCountDown()}
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Question text={interactionMetaData.question} />
            <div className="buttonContainer">
              {interactionMetaData.options.map((option: any) => {
                return (
                  <div key={option.id} className="buttonWrapper">
                    <TriviaButton
                      disabled={false}
                      option={option}
                      onPressTriviaButton={id => {
                        setActiveAnswer(id);
                        submitAnswer(id);
                      }}
                      percent={100}
                      shouldAnimateWidth={false}
                      backgroundColor={
                        activeAnswer === option.id ? curiousBlue : transparent
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default TriviaCountdownQuestion;
