import {useEffect, useCallback} from 'react';
import styled from '@emotion/styled';

// styles
import {black, buttonGreen, buttonRed, transparent} from '../../theme/vars';

// components
import TriviaButton from './TriviaButton';
import Question from './Question';
import TriviaPollHeader from './TriviaPollHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';

import {getAnswer} from '../../utils/trivia';
import {ImageType} from '../media/Image';
import {motion} from 'framer-motion';

import GreenCheck from '../../assets/images/green-check.svg';
import RedX from '../../assets/images/red-x.svg';
import {triviaStep} from '../../constants/Trivia.constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem 0 1rem 0;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type Props = {
  interaction: Record<string, any>;
  isCorrectAnswer?: boolean;
  activeAnswer: string | null;
  backgroundColor: string;
  setStep: (num: number) => void;
  isWatchPartyConnected?: boolean;
};

const TriviaShowAnswerToQuestion = ({
  interaction,
  activeAnswer,
  isCorrectAnswer,
  setStep,
  backgroundColor,
  isWatchPartyConnected,
}: Props) => {
  const {metadata: interactionMetaData} = interaction;
  const joinedTeam = useJoinedTeam();

  const nextStep = useCallback(() => {
    setStep(triviaStep.LOADING_RESULTS);
  }, [setStep]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextStep();
    }, 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [nextStep]);

  const getAnimatedIcon = ({base, alt, className}: ImageType) => {
    return (
      <motion.img
        src={base}
        alt={alt}
        className={className}
        initial={{
          width: isWatchPartyConnected ? '40px' : '65px',
          height: isWatchPartyConnected ? '40px' : '65px',
          scale: '0',
        }}
        animate={{scale: 1}}
        transition={{
          type: 'tween',
          ease: 'easeOut',
          duration: 0.7,
        }}
      />
    );
  };

  const getBackground = (id: any) => {
    const isAnswer = getAnswer(interactionMetaData.options, interaction) === id;
    const wrongAnswer =
      id === activeAnswer &&
      activeAnswer !== getAnswer(interactionMetaData.options, interaction);
    let color;
    if (!isAnswer || !wrongAnswer) {
      color = transparent;
    }
    if (isAnswer) {
      color = buttonGreen;
    }
    if (wrongAnswer) {
      color = buttonRed;
    }

    return color;
  };

  const hasDisabledStyle = (id: any) => {
    return (
      id !== activeAnswer &&
      id !== getAnswer(interactionMetaData.options, interaction)
    );
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <TriviaPollHeader
            header="FAN TRIVIA"
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            type="trivia"
            headerFeature={getAnimatedIcon({
              base: isCorrectAnswer ? GreenCheck : RedX,
            })}
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Question text={interactionMetaData.question} />
            <div className="buttonContainer">
              {interactionMetaData.options.map((option: any) => {
                return (
                  <div key={option.id} className="buttonWrapper">
                    <TriviaButton
                      option={option}
                      disabled={true}
                      hasDisabledStyle={hasDisabledStyle(option.id)}
                      percent={100}
                      shouldAnimateWidth={false}
                      backgroundColor={getBackground(option.id) || ''}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default TriviaShowAnswerToQuestion;
