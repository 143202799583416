import {AssetType} from '../types/assetType';
import store from '../redux/store';

enum MEDIA_CATEGORY {
  GAME_CARD = 'gamecards',
  TEAM_LOGO = 'logos',
  OUTLINE_LOGO = 'outlineLogos',
  SPONSOR = 'sponsors',
  WORDMARK = 'wordmarks',
  FEED_NOT_AVAILABLE = 'feedNotAvailableImages',
  CHAT_UNAVAILABLE = 'chatUnavailableImages'
}

const assetTypeToMediaCategoryMap = new Map([
  [AssetType.TeamLogo, MEDIA_CATEGORY.TEAM_LOGO],
  [AssetType.OutlineLogo, MEDIA_CATEGORY.OUTLINE_LOGO],
  [AssetType.Wordmark, MEDIA_CATEGORY.WORDMARK],
  [AssetType.TriviaSponsorLogo, MEDIA_CATEGORY.SPONSOR],
  [AssetType.PollSponsorLogo, MEDIA_CATEGORY.SPONSOR],
  [AssetType.DjSponsorLogo, MEDIA_CATEGORY.SPONSOR],
  [AssetType.TeamScreamSponsorLogo, MEDIA_CATEGORY.SPONSOR],
  [AssetType.StatsSponsorLogo, MEDIA_CATEGORY.SPONSOR],
  [AssetType.FeedNotAvailableImage, MEDIA_CATEGORY.FEED_NOT_AVAILABLE],
  [AssetType.ChatUnavailableImage, MEDIA_CATEGORY.CHAT_UNAVAILABLE]
]);

type GetGameCardImageProps = {
  gameId?: string;
  teamId?: string;
};

const getMediaImagesForCategory = (category: MEDIA_CATEGORY) => {
  const media = (store.getState() as any).media.mediaData;
  return media ? media[category] : null;
};

export const getGameCardImage = ({gameId, teamId}: GetGameCardImageProps) => {
  if (!gameId || !teamId) return null;
  return getMediaImagesForCategory(MEDIA_CATEGORY.GAME_CARD)?.find((m: any) => {
    return (
      m.name === AssetType.GameCard &&
      m.teamId === teamId &&
      m.gameId === gameId
    );
  });
};

type GetTeamImageProps = {
  teamId?: string;
  type?: AssetType;
};

export const getTeamImage = ({
  teamId,
  type = AssetType.TeamLogo,
}: GetTeamImageProps) => {
  const category = assetTypeToMediaCategoryMap.get(type);
  if (!teamId || !category) return null;
  return getMediaImagesForCategory(category)?.find((m: any) => {
    return m.name === type && m.teamId === teamId;
  });
};
